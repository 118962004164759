<template>
  <article class="comparator-product">
    <button
      role="deletion"
      :aria-label="$t('comparator.page.delete')"
      class="comparator-product__remove icon cross"
      @click="toggleCompare"></button>
    <a
      :href="product._base_url"
      class="comparator-product__image">
      <img
        :src="product.image"
        :alt="product.title" />
    </a>
    <p class="comparator-product__brand">
      {{ product.brand?.name ?? '' }}
    </p>
    <h2 class="comparator-product__title">
      <a :href="product._base_url">
        {{ product.title }}
      </a>
    </h2>
    
    <Price
      v-if="product.price?.unit_price"
      class="comparator-product__price"
      :item="product.price"
      :has-price-range="product.hasPriceRange" />
  </article>
</template>

<script>
  import Price from 'CommonComponents/Price/Price.vue';
  import useProductCompare from 'Composables/useProductCompare.js';

  export default {
    name: 'ComparatorPreviewProduct',
    components: { Price },
    props: {
      product: {
        type: Object,
        required: true,
        default: () => undefined
      }
    },
    setup(props) {
      const { product: currentProduct } = toRefs(props);
      const { toggleCompare } = useProductCompare(currentProduct);
      return { toggleCompare };
    },
  };
</script>