<template>
  <Transition name="comparator-preview">
    <section
      v-if="showPreview"
      class="comparator-preview only_mobile">
      <a
        :href="compareUrl"
        class="comparator-preview__big-compare"
        :class="{ 'comparator-preview__big-compare--disabled': !compareEnabled}">
        {{ compareText }}
      </a>
      <button
        :aria-label="$t('comparator.preview.clear')"
        :title="$t('comparator.preview.clear')"
        class="comparator-preview__big-compare-clear icon cross"
        @click="clearProducts"></button>
    </section>
  </Transition>
  <Transition name="comparator-preview--desktop">
    <section
      v-if="showPreview"
      class="comparator-preview comparator-preview--desktop only_desktop">
      <ComparatorPreviewProduct
        v-for="product in productsToCompare"
        :key="product.id"
        :product="product" />

      <div class="comparator-preview__actions">
        <a
          :href="compareUrl"
          class="comparator-preview__compare"
          :class="{ 'comparator-preview__compare--disabled': !compareEnabled}">
          {{ compareText }}
        </a>
        <button
          class="comparator-preview__clear"
          @click="clearProducts">
          {{ $t('comparator.preview.clear') }}
        </button>
        <button
          :aria-label="$t('comparator.preview.close')"
          :title="$t('comparator.preview.close')"
          class="comparator-preview__close icon cross"
          @click="closePreview">
        </button>
      </div>
    </section>
  </Transition>
</template>

<script>
  import { useI18n } from 'vue-i18n';
  import { mapGetters } from 'CommonUtils/store/state.js';
  import UtilsUrl from 'CommonUtils/url';
  import useProductCompare from 'Composables/useProductCompare.js';
  import ComparatorPreviewProduct from './ComparatorPreviewProduct.vue';
  import DISABLED_URL from 'CommonUtils/disabledUrl';

  export default {
    name: 'ComparatorPreview',
    components: { ComparatorPreviewProduct },
    setup() {
      const { t } = useI18n();
      const { baseUrl, internals, verticalName } = mapGetters('page', {
        baseUrl: 'getBaseUrl',
        internals: 'getInternals',
        verticalName: 'getVerticalName',
      });
      const { productsToCompare, productsLimit, clearProducts } = useProductCompare();

      const compareVertical = computed(() => {
        if (verticalName.value) return verticalName.value;

        const [{ _base_url }] = productsToCompare.value;
        return UtilsUrl.getRouterNavigationMode(_base_url);
      });

      const compareEnabled = computed(() => productsToCompare.value.length > 1);
      const compareText = computed(() => {
        const length = productsToCompare.value.length;
        return length === 1 ? t('comparator.preview.add_more') : t('comparator.preview.compare', { length });
      }); 
      const compareUrl = computed(() => {
        if(!compareEnabled.value) return DISABLED_URL.VOID;

        const productsIds = productsToCompare.value.reduce((ids, { id }) => {
          if (id) ids.push(id);
          return ids;
        }, []).join(',');

        const urlData = {
          url: `${compareVertical.value}/comparador/?products=${productsIds}`,
          baseUrl: baseUrl.value,
          query: internals.value
        };
        return UtilsUrl.getFullUrlWithQuery(urlData) ?? DISABLED_URL.VOID;
      });

      const showPreview = ref(false);
      const setComparatorPreviewVisibility = (show) => showPreview.value = show;
      const closePreview = () => setComparatorPreviewVisibility(false);

      watch(productsToCompare, ({ length }) => setComparatorPreviewVisibility(!!length));

      onBeforeMount(() => {
        if (productsToCompare.value.length) {
          const diacriticsAndAccentsRemovalRegex = /[\u0300-\u036f]/g;
          //TODO: Cuando se mergee la eliminación de lodash, pasar esta normalización a una función y testarla
          const normalizedComparatorCategory = productsToCompare.value[0].category
            .toLowerCase()
            .normalize('NFD')
            .replace(diacriticsAndAccentsRemovalRegex, '');
          const normalizedCategory = compareVertical.value
            .toLowerCase()
            .normalize('NFD')
            .replace(diacriticsAndAccentsRemovalRegex, '');
          if (normalizedComparatorCategory !== normalizedCategory) {
            clearProducts();
          }
        }
      })

      onMounted(() => {
        const show = !!productsToCompare.value.length;
        setComparatorPreviewVisibility(show);
      });

      return {
        clearProducts,
        closePreview,
        compareEnabled,
        compareText,
        compareUrl,
        productsToCompare,
        productsLimit,
        showPreview,
      };
    },
  };
</script>

<style scoped lang="less">
@import 'LessFiles/fontsSizes.less';
@import 'CommonLessFiles/variables/zIndex.less';

.comparator-preview {
  @bg-transition: background-color 0.2s ease;
  @bg-disabled: #e1e1e1;
  @opacity-disabled: .5;
  @bg-color: #fff;
  @text-color: #242424;
  @box-shadow: 0px 0px 4px 0px #00000040;
  @padding-content: 1.5rem 1.375rem;
  @padding-x-mobile-compare: 1.5rem;
  @padding-y-mobile-compare: 1rem;
  @gap-mobile-compare: 1rem;
  @subtle-center-adjustment: 3px;

  .theme(@mode) when (@mode = "dark") {
    background: @text-color;
    color: @bg-color;
    border: none;
  }

  .theme(@mode) when (@mode = "light") {
    background: @bg-color;
    color: @text-color;
    border: 1px solid @text-color;
  }

  .font() {
    font-family: 'ECI-TEXT';
    font-size: @font-size-xs;
    line-height: 1.375rem;
    letter-spacing: 0.3px;
    color: @text-color;
    font-weight: bold;  
    text-transform: uppercase;
  }
  .disabled() {
    pointer-events: none;
    opacity: @opacity-disabled;
    background-color: @bg-disabled;
    color: @text-color;
  }
  
  position: fixed;
  bottom: 5rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: @_zComparatorPreview;
  background-color: @bg-color;

  &--desktop {
    bottom: 0;
    left: 0;
    width: 100%;
    transform: none;
    padding: @padding-content;
    background-color: @bg-color;

    box-shadow: @box-shadow;
    display: grid;
    grid-template-columns: repeat(v-bind(productsLimit), 1fr) auto;
    justify-content: center;
    gap: 1rem;

    &-enter-active,
    &-leave-active {
      transition: 0.25s;
      transition-timing-function: ease-out;    
      transform: translateY(0);
      opacity: 1;
    }
    
    &-enter-from,
    &-leave-to {
      transform: translateY(130%);
      transition-timing-function: ease-in;
      transition: 0.2s;
      opacity: 0;
    }
  }

  &__big-compare {
    .font();
    .theme("dark");
    display: inline-flex;
    cursor: pointer;
    text-decoration: none;
    padding: @padding-y-mobile-compare @padding-x-mobile-compare;
    padding-right: (@padding-x-mobile-compare * 2) + @gap-mobile-compare;
    transition: @bg-transition;
    white-space: nowrap;

    &--disabled {
      .disabled();
    }
  }

  &__big-compare--disabled ~
  &__big-compare-clear {
    color: @text-color;
  }
  
  &__big-compare-clear {
    background-color: transparent;
    color: @bg-color;
    border: 0;
    cursor: pointer;
    position: absolute;
    height: 100%;
    right: 0;
    padding: @subtle-center-adjustment @padding-x-mobile-compare 0 @gap-mobile-compare;
  }

  &__actions {
    grid-column-start: -2;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    gap: 1rem;
  }

  &__compare,
  &__clear {
    .font();
    cursor: pointer;
    padding: 1rem 1.5rem;
    white-space: nowrap;
  }

  &__compare {
    .theme("dark");
    text-decoration: none;
    transition: @bg-transition;

    &--disabled {
      .disabled();
    }
  }

  &__clear {
    .theme("light");
  }
  
  &__close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background-color: transparent;
    border: 0;
    cursor: pointer;

    &::before {
      font-size: 1.5rem;
    }
  }

  &-enter-active,
  &-leave-active {
    transition: 0.25s;
    transition-timing-function: ease-out;    
    transform: translate(-50%, 0);
    opacity: 1;
  }
  
  &-enter-from,
  &-leave-to {
    transform: translate(-50%, 130%);
    transition-timing-function: ease-in;
    transition: 0.2s;
    opacity: 0;
  }
  }</style>